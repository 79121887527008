import Loader from 'components/Loader';

const routes = [
	{
		path: '/',
		name: 'Dashboard',
		container: Loader(() => import('containers/Widgets')),
	},
	{
		path: '/analytics',
		name: 'Analytics',
		container: Loader(() => import('containers/Analytics')),
		layout: 'data',
	},
	{
		path: '/reports',
		name: 'Reports',
		container: Loader(() => import('containers/Reports')),
		layout: 'report',
		routes: [
			{
				path: '/reports/:reportType',
				container: Loader(() => import('containers/Reports')),
				layout: 'report',
			},
		],
	},
	{
		path: '/menu',
		name: 'Menu',
		layout: 'menu',
		routes: [
			{
				path: '/menu/management/',
				name: 'Menu Management',
				container: Loader(() => import('containers/MenuManagement')),
			},
			{
				path: '/menu/mapping',
				name: 'Menu Mapping',
				container: Loader(() => import('containers/Mapping')),
			},
		],
	},
	{
		path: '/orders-control',
		name: 'Orders Control',
		layout: 'data',
		routes: [
			{
				path: '/orders-control',
				name: 'Orders Control',
				container: Loader(() => import('containers/OrdersControl')),
			},
		],
	},
	{
		path: '/manage',
		name: 'Manage',
		routes: [
			{
				path: '/manage/v2/integrations',
				name: 'Integrations',
				layout: 'report',
				routes: [
					{
						path: '/manage/v2/integrations',
						name: 'Integrations',
						container: Loader(() => import('containers/v2/Integrations')),
					},
					{
						path: '/manage/v2/integrations/:action/:app',
						name: 'Integrations',
						container: Loader(() => import('containers/v2/Integrations')),
					},
					{
						path: '/manage/v2/integrations/pos/oauth/:app/:status',
						name: 'Integrations',
						container: Loader(() => import('containers/v2/PosOauth')),
					},
					{
						path: '/manage/v2/integrations/pos/oauth/result/:app/:status',
						name: 'Integrations',
						container: Loader(() =>
							import(
								'components/Integrations/v2/PosIntegrationConnectionSuccess'
							),
						),
					},
				],
			},
			{
				path: '/manage/payments',
				name: 'Payments Setup',
				container: Loader(() => import('containers/Payments')),
				layout: 'report',
			},
			{
				path: '/manage/storefronts',
				name: 'Storefronts',
				container: Loader(() => import('containers/Storefront')),
				routes: [
					{
						path: '/manage/storefronts',
						name: 'Storefronts',
						container: Loader(() => import('containers/Storefront')),
					},
					{
						path: '/manage/storefronts/edit/:id',
						name: 'Edit Storefronts',
						container: Loader(() => import('containers/Storefront')),
					},
				],
			},
			{
				path: '/manage/kiosk',
				name: 'Kiosk',
				routes: [
					{
						path: '/manage/kiosk',
						name: 'Kiosk',
						container: Loader(() => import('containers/ManageKiosk')),
					},
				],
			},
			{
				path: '/manage/orders-display',
				name: 'Orders Display',
				container: Loader(() => import('containers/ManageOrderDisplay')),
				routes: [
					{
						path: '/manage/orders-display',
						name: 'Orders Display',
						container: Loader(() => import('containers/ManageOrderDisplay')),
					},
				],
			},
			{
				path: '/manage/fresh-kds',
				name: 'Fresh KDS',
				container: Loader(() => import('containers/FreshKds')),
			},
		],
	},
	{
		path: '/stripe',
		name: 'Stripe',
		routes: [
			{
				path: '/stripe/callback',
				name: 'Stripe',
				container: Loader(() => import('containers/StripeAuth')),
				layout: 'redirect',
			},
		],
	},
	{
		path: '/settings',
		name: 'Settings',
		container: Loader(() => import('containers/Settings')),
		routes: [
			{
				path: '/settings',
				name: 'Settings',
				container: Loader(() => import('containers/Settings')),
			},
		],
	},
	{
		path: '/profile',
		name: 'Profile',
		container: Loader(() => import('containers/Profile')),
		routes: [
			{
				path: '/profile/invoices',
				name: 'Invoices',
				container: Loader(() => import('containers/Profile')),
			},
		],
	},
];

export const allPlainNavigableRoutes = [
	//list of all routes which can be navigated to directly without extra params
	'analytics',
	'reports/order',
	'reports/storefront-order',
	'reports/control',
	'reports/adjustment',
	'reports/cancellations',
	'reports/item',
	'reports/modifier',
	'menu/management',
	'menu/mapping',
	'orders-control',
	'manage/v2/integrations',
	'manage/storefronts',
	'stripe/callback',
	'settings',
	'profile/invoices',
];

export default routes;
